/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:51
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:58
 */
export default {
  appkey: '27823140', // API网关提供的appkey
  secret: 'XeiiPJVSxByCIzLWNvsF', // API网关提供的secret
  ip: '121.204.163.62', // API网关IP地址
  playMode: 0, // 初始播放模式：0-预览，1-回放
  port: 10443, // 端口
  snapDir: 'D:\\SnapDir', // 抓图存储路径
  videoDir: 'D:\\VideoDir', // 紧急录像或录像剪辑存储路径
  layout: '2x2', // playMode指定模式的布局
  enableHTTPS: 1, // 是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
  encryptedFields: 'secret', // 加密字段，默认加密领域为secret
  showToolbar: 1, // 是否显示工具栏，0-不显示，非0-显示
  showSmart: 1, // 是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
  // buttonIDs: '' //自定义工具条按钮
  buttonIDs: '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769', // 自定义工具条按钮
  reconnectTimes: 5 // 重连次数，回放异常情况下有效
  // reconnectDuration: 4;                             // 每次重连的重连间隔 >= reconnectTime
}
