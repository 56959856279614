/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:51
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:48
 */
export default {
  tip: '提示',
  title: ' 车联网平台',
  logoutTip: '退出系统, 是否继续?',
  submitText: '确定',
  cancelText: '取消',
  search: '请输入搜索内容',
  menuTip: '没有发现菜单',
  wel: {},
  route: {},
  login: {},
  navbar: {
    logOut: '退出登录',
    userinfo: '个人信息',
    dashboard: '首页',
    lock: '锁屏',
    bug: '没有错误日志',
    bugs: '条错误日志',
    screenfullF: '退出全屏',
    screenfull: '全屏',
    language: '中英文',
    notice: '消息通知',
    theme: '主题',
    color: '换色'
  },
  tagsView: {
    search: '搜索',
    menu: '更多',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  table: {
    add: '添加',
    view: '查看',
    del: '删除',
    edit: '编辑',
    batchDel: '批量删除',
    batchClear: '批量清空',
    search: '搜素',
    reset: '重置',
    batchTip: '当前已选择 0 项数据'
  },
  form: {},
  tag: {
    closeAll: '关闭所有',
    closeOther: '关闭其他',
    closeCurrent: '关闭当前',
    refresh: '刷新页面',
    more: '更多'
  },
  dialog: {
    title: '提示',
    confirm: '确定',
    cancel: '取消'
  }
}
