export const TARGET_WIDTH = 1920
export const TARGET_HEIGHT = 1080
export const MAX_TEMPERATURE = 37.3 // 温度正常极值
export const PARKID = '5012e' // 艾德园区固定编码
export const LAMPPK = '5Lj6J72S0Dh9Xl5F' // 灯控产品 productKey
// 综合环境值的设备deviceName
export const ENVIRONMENTAL = [
  '2mop7R0L6sCWH6q5',
  'n4uhjCr1964R9B1P',
  '2mop7R0L6sCWH6q5',
  '2mop7R0L6sCWH6q5',
  '2mop7R0L6sCWH6q5',
  'Szh5Thf4r88xj5VR',
  'D39w0MCF0Iue30Cr',
  '891zWA5h95Of094q',
  'Z3C672XFEgvyTXI4'
]
// ph对照 值
export const PHVALUE = [
  [[0, 4.5], '极强酸性'],
  [[4.5, 5.5], '强酸性'],
  [[5.5, 6.5], '酸性'],
  [[6.5, 7.5], '中性'],
  [[7.5, 8.5], '碱性'],
  [[8.5, 9.5], '强碱性'],
  [[9.5, 99], '极强碱性']
]
// 噪音对照 值
export const NOISE = [
  [[0, 20], '很静、几乎感觉不到'],
  [[20, 40], '安静、犹如轻声语'],
  [[40, 60], '一般普通室内谈话'],
  [[60, 70], '吵闹、有损神经'],
  [[70, 90], '很吵、神经细胞受到破坏'],
  [[90, 100], '吵闹加剧、听力受损']
]
// PM对照 值
export const PM = [
  [[0, 35], '优'],
  [[35, 75], '良'],
  [[75, 115], '轻度污染'],
  [[115, 150], '中度污染'],
  [[150, 250], '重度污染'],
  [[250, 999], '严重污染']
]
// 风速对照 值
export const WINDSPEED = [
  [[0.0, 0.3], '无风'],
  [[0.3, 1.6], '软风'],
  [[1.6, 3.4], '轻风'],
  [[3.4, 5.5], '微风'],
  [[5.5, 8.0], '和风'],
  [[8.0, 10.8], '清劲风'],
  [[10.8, 13.9], '强风'],
  [[13.9, 17.2], '疾风'],
  [[17.2, 20.8], '大风'],
  [[20.8, 24.5], '烈风'],
  [[24.5, 28.5], '狂风'],
  [[28.5, 32.7], '暴风'],
  [[32.7, 999], '清劲风']
]
// 车辆分类
export const codeDict = [
  {
    label: 'VIP车辆',
    code: 'C0000'
  },
  {
    label: '内部车辆',
    code: 'C0001'
  },
  {
    label: '访客车辆',
    code: 'C0002'
  },
  {
    label: '陌生车辆',
    code: 'C0003'
  },
  {
    label: '无牌车辆',
    code: 'C0004'
  },
  {
    label: '临时车辆',
    code: 'C0005'
  },
  {
    label: '固定车辆',
    code: 'C0006'
  }
]
// 水电值特殊处理
export const WAPK = ['H4a2K382Ov1Ts5sd', 'g0saEDt5mJRvyF3T']
// 水电特殊处理prop
export const props = ['today']
// 不需要显示的监控点位集
export const FILTERSMONITOR = []

export const ENVIRONMENT = {
  pm25: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '优'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '优'
    },
    '2': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '良'
    },

    '3': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻度污染'
    },

    '4': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '中度污染'
    },
    '4': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '重度污染'
    },
    '4': {
      rgb: 'rgba(193, 0, 0, 1)',
      description: '严重污染'
    }
  },
  noise: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无噪音'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无噪音'
    },
    '2': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻微噪音'
    },

    '3': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '中度噪音'
    },

    '4': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '严重噪音'
    }
  },
  pm10: {
    '999': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '正常'
    },
    '1': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '正常'
    },
    '2': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻微污染'
    },

    '3': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '重度污染'
    }

    // "": "rgba(101, 252, 255, 1)",
    // 正常: "rgba(23, 230, 161, 1)",
    // 轻微污染: "rgba(255, 204, 29, 1)",
    // 重度污染: "rgba(255, 48, 48, 1)",
  },
  temperature: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '优'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '严寒'
    },
    '2': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '寒冷'
    },

    '3': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '凉爽'
    },
    '4': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '舒适'
    },
    '5': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '温暖'
    },
    '6': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '炎热'
    },
    '7': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '酷热'
    }

    // "": "rgba(101, 252, 255, 1)",
    // 优: "rgba(101, 252, 255, 1)",
    // 良: "rgba(101, 252, 255, 1)",

    // 凉爽: "rgba(23, 230, 161, 1)",
    // 舒适: "rgba(23, 230, 161, 1)",

    // 温暖: "rgba(255, 204, 29, 1)",
    // 炎热: "rgba(255, 144, 0, 1)",
    // 酷热: "rgba(255, 48, 48, 1)"
  },
  humidity: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '干燥'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '干燥'
    },
    '2': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '适宜'
    },
    '3': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '潮湿'
    }
  },

  // "": "rgba(101, 252, 255, 1)",
  // 干燥: "rgba(101, 252, 255, 1)",
  // 适宜: "rgba(23, 230, 161, 1)",
  // 潮湿: "rgba(255, 204, 29, 1)"
  wind: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无风'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无风'
    },
    '2': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '软风'
    },
    '3': {
      rgb: 'rgba(23, 230, 255, 1)',
      description: '轻风'
    },

    '4': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '微风'
    },
    '5': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '和风'
    },
    '6': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '清劲风'
    },

    '7': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '强风'
    },
    '8': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '疾风'
    },

    '9': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '大风'
    },
    '10': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '烈风'
    },

    '11': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '暴风'
    },
    '12': {
      rgb: 'rgba(193, 0, 0, 1)',
      description: '台风'
    }
  },
  rainfall: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无雨'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无雨'
    },
    '2': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '小雨'
    },

    '3': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '中雨'
    },
    '4': {
      rgb: 'rgba(23, 230, 161, 1)',
      description: '大雨'
    },
    '5': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '暴雨'
    },
    '6': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '大暴雨'
    },
    '7': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '特大暴雨'
    }

    // "": "rgba(101, 252, 255, 1)",
    // 无雨: "rgba(101, 252, 255, 1)",
    // 小雨: "rgba(101, 252, 255, 1)",

    // 中雨: "rgba(23, 230, 161, 1)",
    // 大雨: "rgba(23, 230, 161, 1)",

    // 暴雨: "rgba(255, 204, 29, 1)",
    // 大暴雨: "rgba(255, 144, 0, 1)",
    // 特大暴雨: "rgba(255, 48, 48, 1)"
  },
  ph: {
    '999': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '中性'
    },
    '1': {
      rgb: 'rgba(101, 252, 255, 1)',
      description: '中性'
    },
    '2': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '酸性'
    },

    '3': {
      rgb: 'rgba(255, 204, 29, 1)',
      description: '碱性'
    },
    '4': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '强酸性'
    },
    '5': {
      rgb: 'rgba(255, 144, 0, 1)',
      description: '强碱性'
    },
    '6': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '极强酸性'
    },
    '7': {
      rgb: 'rgba(255, 48, 48, 1)',
      description: '极强碱性'
    }

    // "": "rgba(101, 252, 255, 1)",
    // 中性: "rgba(101, 252, 255, 1)",
    // 酸性: "rgba(255, 204, 29, 1)",
    // 碱性: "rgba(255, 204, 29, 1)",

    // 强酸性: "rgba(255, 144, 0, 1)",
    // 强碱性: "rgba(255, 144, 0, 1)",

    // 极强酸性: "rgba(255, 48, 48, 1)",
    // 极强碱性: "rgba(255, 48, 48, 1)"
  }
}
export const ENVIRONMENT_DATA = {
  // 湿度
  humidity: [
    {
      level: '1',
      value: 40,
      unit: '%',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '干燥'
    },
    {
      level: '2',
      value: 80,
      unit: '%',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '适宜'
    },
    {
      level: '3',
      value: 100,
      unit: '%',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '潮湿'
    }
  ],
  pm10: [
    {
      level: '1',
      value: 50,
      unit: 'μg/m³',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '正常'
    },
    {
      level: '2',
      value: 150,
      unit: 'μg/m³',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻微污染'
    },
    {
      level: '3',
      value: 300,
      unit: 'μg/m³',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '严重污染'
    },
    {
      level: '4', // 防止超过300的数值出现
      value: 1000,
      unit: 'μg/m³',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '严重污染'
    }
  ],
  noise: [
    {
      level: '1',
      value: 30.0,
      unit: 'db',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无噪音'
    },
    {
      level: '2',
      value: 59.9,
      unit: 'db',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻微噪音'
    },
    {
      level: '3',
      value: 89.9,
      unit: 'db',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '中度噪音'
    },
    {
      level: '4',
      value: 300,
      unit: 'db',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '严重噪音'
    },
    {
      level: '4',
      value: 3000,
      unit: 'db',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '严重噪音'
    }
  ],
  pm25: [
    {
      level: '1',
      value: 35,
      unit: 'μg/m³',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '优'
    },
    {
      level: '2',
      value: 75,
      unit: 'μg/m³',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '良'
    },
    {
      level: '3',
      value: 115,
      unit: 'μg/m³',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '轻度污染'
    },
    {
      level: '4',
      value: 150,
      unit: 'μg/m³',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '中度污染'
    },
    {
      level: '5',
      value: 250,
      unit: 'μg/m³',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '重度污染'
    },
    {
      level: '6',
      value: 3000,
      unit: 'μg/m³',
      rgb: 'rgba(193, 0, 0, 1)',
      description: '严重污染'
    }
  ],
  temp: [
    {
      level: '1',
      value: 0,
      unit: '℃',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '严寒'
    },
    {
      level: '2',
      value: 10,
      unit: '℃',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '寒冷'
    },
    {
      level: '3',
      value: 20,
      unit: '℃',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '凉爽'
    },
    {
      level: '4',
      value: 25,
      unit: '℃',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '舒适'
    },
    {
      level: '5',
      value: 30,
      unit: '℃',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '温暖'
    },
    {
      level: '6',
      value: 36,
      unit: '℃',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '炎热'
    },
    {
      level: '7',
      value: 100,
      unit: '℃',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '酷热'
    }
  ],
  wind: [
    {
      level: '1',
      value: 0.2,
      unit: 'm/s',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无风'
    },
    {
      level: '2',
      value: 1.5,
      unit: 'm/s',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '软风'
    },
    {
      level: '3',
      value: 3.3,
      unit: 'm/s',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '轻风'
    },
    {
      level: '4',
      value: 5.4,
      unit: 'm/s',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '微风'
    },
    {
      level: '5',
      value: 7.9,
      unit: 'm/s',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '和风'
    },
    {
      level: '6',
      value: 10.7,
      unit: 'm/s',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '清劲风'
    },
    {
      level: '7',
      value: 13.8,
      unit: 'm/s',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '强风'
    },
    {
      level: '8',
      value: 17.1,
      unit: 'm/s',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '疾风'
    },
    {
      level: '9',
      value: 20.7,
      unit: 'm/s',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '大风'
    },
    {
      level: '10',
      value: 24.4,
      unit: 'm/s',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '烈风'
    },
    {
      level: '11',
      value: 28.4,
      unit: 'm/s',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '狂风'
    },
    {
      level: '12',
      value: 32.6,
      unit: 'm/s',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '暴风'
    },
    {
      level: '13',
      value: 1000,
      unit: 'm/s',
      rgb: 'rgba(193, 0, 0, 1)',
      description: '台风'
    }
  ],
  ph: [
    {
      level: '1',
      value: 4.5,
      unit: 'pH',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '极强酸性'
    },
    {
      level: '2',
      value: 5.5,
      unit: 'pH',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '强酸性'
    },
    {
      level: '3',
      value: 6.5,
      unit: 'pH',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '酸性'
    },
    {
      level: '4',
      value: 7.5,
      unit: 'pH',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '中 性'
    },
    {
      level: '5',
      value: 8.5,
      unit: 'pH',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '碱性'
    },
    {
      level: '6',
      value: 9.5,
      unit: 'pH',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '强碱性'
    },
    {
      level: '7',
      value: 500,
      unit: 'pH',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '极强碱性'
    }
  ],
  rain: [
    {
      level: '1',
      value: 0,
      unit: 'mm',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '无雨'
    },
    {
      level: '2',
      value: 10,
      unit: 'mm',
      rgb: 'rgba(101, 252, 255, 1)',
      description: '小雨'
    },
    {
      level: '3',
      value: 25,
      unit: 'mm',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '中雨'
    },
    {
      level: '4',
      value: 50,
      unit: 'mm',
      rgb: 'rgba(23, 230, 161, 1)',
      description: '大雨'
    },
    {
      level: '5',
      value: 100,
      unit: 'mm',
      rgb: 'rgba(255, 204, 29, 1)',
      description: '暴雨'
    },
    {
      level: '6',
      value: 200,
      unit: 'mm',
      rgb: 'rgba(255, 144, 0, 1)',
      description: '大暴雨'
    },
    {
      level: '7',
      value: 5000,
      unit: 'mm',
      rgb: 'rgba(255, 48, 48, 1)',
      description: '特大暴雨'
    }
  ],
  windDirection: [
    {
      level: '1',
      value: 11.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '北风'
    },
    {
      level: '2',
      value: 56.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '东北风'
    },
    {
      level: '3',
      value: 101.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '东风'
    },
    {
      level: '4',
      value: 146.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '东南风'
    },
    {
      level: '5',
      value: 191.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '南风'
    },
    {
      level: '6',
      value: 236.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '西南风'
    },
    {
      level: '7',
      value: 281.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '西风'
    },
    {
      level: '8',
      value: 326.25,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '西北风'
    },
    {
      level: '9',
      value: 360,
      unit: '',
      rgb: 'rgba(10, 239, 242, 1) ',
      description: '北风'
    }
  ]
}

export const DEVICE_DATA = [
  {
    name: '水浸传感器',
    productKey: '5983w5FTMPiZ7uH9',
    params: [
      {
        identity: 'flooding_state',
        name: '水浸状态',
        unit: '',
        computed: function(val) {
          let value = ''
          if (val) {
            value = '水浸'
          } else {
            value = '未被水浸'
          }
          return value
        }
      },
      {
        identity: 'battery_voltage',
        name: '电池电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '土壤温湿度传感器',
    productKey: 'wym24lc94iw0B4zR',
    params: [
      {
        identity: 'temperature',
        name: '土壤温度',
        unit: '℃',
        computed: null
      },
      {
        identity: 'humidity',
        name: '土壤湿度',
        unit: '%RH',
        computed: null
      },
      {
        identity: 'battery_voltage',
        name: '电池电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '液位传感器',
    productKey: 'gQ34X55nc6gEEYB2',
    params: [
      {
        identity: 'Liquid_value',
        name: '液位值',
        unit: 'cm',
        computed: null
      },
      {
        identity: 'Liquid_threshold_max',
        name: '液位告警阈值上限',
        unit: 'mm',
        computed: null
      },
      {
        identity: 'Liquid_state',
        name: '液位状态',
        unit: '',
        computed: function(val) {
          let value = ''
          if (val == 2) {
            value = '液位过低'
          } else if (val == 1) {
            value = '液位过高'
          } else if (val == 0) {
            value = '正常'
          }
          return value
        }
      },
      {
        identity: 'Acquisition_time',
        name: '采样时间',
        unit: '',
        computed: null
      },
      {
        identity: 'Range_max',
        name: '液位量程上限',
        unit: 'mm',
        computed: null
      },
      {
        identity: 'Signal_value',
        name: '信号强度',
        unit: '',
        computed: null
      },
      {
        identity: 'Battery_voltage',
        name: '电池电压',
        unit: 'V',
        computed: null
      },
      {
        identity: 'Hardware_version',
        name: '硬件版本',
        unit: '',
        computed: null
      },
      {
        identity: 'Upload_cycle',
        name: '上传周期',
        unit: '',
        computed: null
      },
      {
        identity: 'Liquid_threshold_min',
        name: '液位告警阈值下限',
        unit: 'mm',
        computed: null
      },
      {
        identity: 'Range_min',
        name: '液位量程下限',
        unit: 'mm',
        computed: null
      },
      {
        identity: 'Acquisition_cycle',
        name: '采集周期',
        unit: 's',
        computed: null
      },
      {
        identity: 'Software_version',
        name: '软件版本',
        unit: '',
        computed: null
      },
      {
        identity: 'Battery_value',
        name: '电池电量',
        unit: '%',
        computed: null
      }
    ]
  },
  {
    name: '风速传感器',
    productKey: 'U4jkjZ2ikX1ExUOI',
    params: [
      {
        identity: 'speed',
        name: '风速',
        unit: 'm/s',
        computed: null
      },
      {
        identity: 'battery_voltage',
        name: '电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '风向检测器',
    productKey: 'KYxxq1PyL7obD8nA',
    params: [
      {
        identity: 'direction',
        name: '风向',
        unit: '',
        computed: null
      },
      {
        identity: 'battery_voltage',
        name: '电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '雨量传感器',
    productKey: 'BTa863298Bp6R1y4',
    params: [
      {
        identity: 'rainfall',
        name: '累计雨量',
        unit: '毫米/小时',
        computed: null
      },
      {
        identity: 'battery_voltage',
        name: '电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '温湿度传感器',
    productKey: 'WJ5vunLo6tS1MD7k',
    params: [
      {
        identity: 'anti_theft_state',
        name: '防拆状态',
        unit: '',
        computed: function(val) {
          let value = ''
          if (val) {
            value = '拆除'
          } else {
            value = '未拆'
          }
          return value
        }
      },
      {
        identity: 'temperature',
        name: '室内温度',
        unit: '℃',
        computed: null
      },
      {
        identity: 'humidity',
        name: '室内湿度',
        unit: '%RH',
        computed: null
      },
      {
        identity: 'battery_voltage',
        name: '电池电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '多合一气象监测器',
    productKey: 'H3o0f1DB21Ziqh7l',
    params: [
      {
        identity: 'temperature',
        name: '室外温度',
        unit: '℃',
        computed: null
      },
      {
        identity: 'humidity',
        name: '室外湿度',
        unit: '%RH',
        computed: null
      },
      {
        identity: 'pm10',
        name: 'PM10',
        unit: 'ug/m3',
        computed: null
      },
      {
        identity: 'pm25',
        name: 'PM2.5',
        unit: 'ug/m3',
        computed: null
      },
      {
        identity: 'noise',
        name: '噪声',
        unit: 'dB',
        computed: null
      }
    ]
  },
  {
    name: '烟雾报警器',
    productKey: '4FItghD46Mmn1agh',
    params: [
      {
        identity: 'state',
        name: '状态',
        unit: '',
        computed: function(val) {
          let value = ''
          switch (val) {
            case '1':
              value = '火警'
              break
            case '2':
              value = '故障'
              break
            case '3':
              value = '低电压'
              break
            case '4':
              value = '火警消除'
              break
            case '5':
              value = '故障消除'
              break
            case '6':
              value = '低电压恢复'
              break
            case '0xd':
              value = '按键自检'
              break
            case '0xe':
              value = '探测器上电'
              break
          }
          return value
        }
      },
      {
        identity: 'battery_voltage',
        name: '电压',
        unit: 'V',
        computed: null
      }
    ]
  },
  {
    name: '声光报警器',
    productKey: 'E16CG7mlW6e37GAn',
    params: [
      {
        identity: 'state',
        name: '声光报警器状态',
        unit: '',
        computed: function(val) {
          let value = ''
          if (val) {
            value = '关闭状态'
          } else {
            value = '开启状态'
          }
          return value
        }
      }
    ]
  },
  {
    name: '热水表',
    productKey: 'g0saEDt5mJRvyF3T',
    params: [
      {
        identity: 'water_volume',
        name: '水表用量',
        unit: 'm³',
        computed: null
      },
      {
        identity: 'time',
        name: '时间',
        unit: '',
        computed: null
      }
    ]
  },
  {
    name: '三相电表',
    productKey: 'H4a2K382Ov1Ts5sd',
    params: [
      {
        identity: 'current_totalElectric_energy',
        name: '当前总电能',
        unit: 'kW·h',
        computed: null
      },
      {
        identity: 'current_reverse_electrical_energy',
        name: '当前反向电能',
        unit: 'kW·h',
        computed: null
      },
      {
        identity: 'current_forward_electric_energy',
        name: '当前正向电能',
        unit: 'kW·h',
        computed: null
      },
      {
        identity: 'electricity_a',
        name: '电流A',
        unit: 'A',
        computed: null
      },
      {
        identity: 'voltage_a',
        name: '电压A',
        unit: 'V',
        computed: null
      },
      {
        identity: 'activePower_t',
        name: '有用功率T',
        unit: 'kw',
        computed: null
      },
      {
        identity: 'activePower_a',
        name: '有用功率A',
        unit: 'kw',
        computed: null
      },
      {
        identity: 'time',
        name: '时间',
        unit: 's',
        computed: null
      }
    ]
  }
]
