/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-30 16:40:22
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:45
 */
import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import enLocale from './en'
import zhLocale from './zh'
import Storage from '@/util/storage'
import Website from '@/conf/website'
const { fn_storage__get } = Storage(Website)
Vue.use(VueI18n)
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
}

const i18n = new VueI18n({
  locale: fn_storage__get({ name: 'language' }) || 'zh',
  messages
})

export default i18n
